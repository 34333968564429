/*******************************
 *	ResponsiveNavigation
 *	jQuery plugin
 *
 *  (C) Copyright OWNBIT LLC
 *  https://ownbit.net
 *
 ******************************/



/*******************************
 * MenuItemFolder
 ******************************/

var MenuItemFolder = function(element, options) {
	this.options = options;

	this.$element = $(element);
	this.$ul = this.$element.find('> ul');
	this.init();
};

MenuItemFolder.defaults = {
	slideToggle: true,
	activeClass: 'active',
};

MenuItemFolder.prototype.init = function() {
	var _this = this;

	var $indicator = $($.parseHTML(
		'<span class="item-folder-indicator"><span class="fa fa-angle-down"></span></span>'
	));
	if(this.$ul.length > 0) {
		this.$element.find('>a').append($indicator);
		//this.$element.append($indicator);
	}

	$indicator.click(function(e) {
		e.preventDefault();
		e.stopPropagation();
		$indicator.toggleClass(_this.options.activeClass);
		_this.toggle();
	});

};

MenuItemFolder.prototype.toggle = function() {
	if(this.options.jsToggle) {
		this.$ul.slideToggle();
	}
	this.$ul.toggleClass(this.options.activeClass);
};

/**
 * ResponsiveNavigation jQuery plugin
 * @param option
 * @returns {*}
 * @constructor
 */
$.fn.MenuItemFolder = function( option ) {
	return this.each( function () {
		var $this = $(this);
		var data = $this.data('MenuItemFolder');
		var options = $.extend(
			{},
			MenuItemFolder.defaults,
			$this.data(),
			typeof option == 'object' && option
		);

		if(!data) {
			$this.data('MenuItemFolder', (data = new MenuItemFolder(this, options)));
		}
	});
};


/*******************************
 * ResponsiveNavigation
 ******************************/

var ResponsiveNavigation = function(element, options) {
	this.$element = $(element);

	/*
	 * Switching from "nav ul.menu"
	 * to ".nav-toggle-container"
	 * to be independent of the dom structure.
	 *
	 * Be sure that you added the "nav-toggle-container"
	 * class to your menu ul using
	 * 'menu_class' => 'clearfix nav-toggle-container'
	 * in the wordpress wp_nav_menu($args) argument array.
	 *
	 */
	this.$ul = this.$element.find('.nav-toggle-container');

	this.$btnToggle = this.$element.find('.btn-nav-toggle');
	this.$btnToggleSearchBar = this.$element.find('.btn-search-toggle');
	this.$searchBar = $('header.main .search-form');
	this.options = options;

	this.init();
};

ResponsiveNavigation.defaults = {
	slideToggle: false,
};

ResponsiveNavigation.prototype.init = function() {
	var _this = this;
	this.$btnToggle.click(function() {
		_this.$btnToggle.toggleClass(_this.options.activeClass);
		_this.toggleUl();
	});

	var $lis = this.$ul.find('li');
	$lis.MenuItemFolder();

	this.$btnToggleSearchBar.click(function() {
		_this.toggleSearchBar();
	});
};


ResponsiveNavigation.prototype.toggleUl = function() {
	if(this.options.jsToggle) {
		this.$ul.slideToggle();
	}
	this.$ul.toggleClass('opened'); // legacy
	this.$ul.toggleClass(this.options.activeClass);
};

ResponsiveNavigation.prototype.toggleSearchBar = function() {
	this.$searchBar.slideToggle();
};

/**
 * ResponsiveNavigation jQuery plugin
 * @param option
 * @returns {*}
 * @constructor
 */
$.fn.ResponsiveNavigation = function( option ) {
	return this.each( function () {
		var $this = $(this);
		var data = $this.data('ResponsiveNavigation');
		var options = $.extend(
			{},
			ResponsiveNavigation.defaults,
			$this.data(),
			typeof option == 'object' && option
		);

		if(!data) {
			$this.data('ResponsiveNavigation', (data = new ResponsiveNavigation(this, options)));
		}
	});
};
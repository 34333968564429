import Swiper from 'swiper'

$(() => {
  $('.wpce-slider-integrated').each(function () {
    let $slider = $(this)

    let swiper = new Swiper(
      `#${$slider.data('swiper-id')}`,
      $slider.data('options')
    )

    // Had to include manually because of a bug in chrome
    $slider.find('.swiper-button-next').click(() => {
      swiper.slideNext()
    })
    $slider.find('.swiper-button-prev').click(() => {
      swiper.slidePrev()
    })
  })
})

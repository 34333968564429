import Swiper from 'swiper'

$(() => {
  $('.wpce-tabs').each(function () {
    let $tabs = $(this)
    let swiper_id = $tabs.data('swiper-id')

    let $nav = $tabs.find('.tabs-nav')
    let $navItems = $nav.find('li')
    let activeClass = 'active'
    let $ul = $nav.find('ul')
    let resizeTimer
    let $toggleButton = $tabs.find('.tabs-nav')
    let mobileClass = 'is-mobile'
    let spaceLimit = 9999

    let hasEnoughSpace = () => {
      let hasEnoughSpace = false
      let navWidth = $nav.outerWidth()
      let necessarySpace = 0
      $navItems.each(function () {
        necessarySpace += $(this).outerWidth(true)
      })
      if (navWidth + 2 >= necessarySpace) {
        hasEnoughSpace = true
        if (!$nav.hasClass(mobileClass)) spaceLimit = necessarySpace
      }
      return hasEnoughSpace
    }

    let isMobile = () => !hasEnoughSpace() && !($nav.outerWidth() >= spaceLimit)

    let setMobile = () => {
      if (isMobile()) {
        $nav.addClass(mobileClass)
        $nav.css('height', $ul.find('li.active').outerHeight() + 'px')
      } else {
        $nav.removeClass(mobileClass)
        $nav.css('height', 'auto')
      }
    }

    setMobile()

    $(window).on('resize', () => {
      clearTimeout(resizeTimer)
      resizeTimer = setTimeout(() => {
        setMobile()
      }, 250)
    })

    $toggleButton.click(() => {
      if (isMobile()) {
        $nav.toggleClass('active')
      }
    })

    let swiper = new Swiper(`#${swiper_id}`, {
      threshold: 30,
      autoHeight: true
    })

    swiper.on('slideChange', () => {
      $navItems.removeClass(activeClass)
      $navItems.eq(swiper.activeIndex).addClass(activeClass)
    })

    $(`#${swiper_id} .accordion-heading`).on('accordion:toggled', () => {
      swiper.updateAutoHeight(300)
    })

    $navItems.click(function () {
      swiper.slideTo($(this).attr('data-index'), 500)

      if ($(this).attr('data-ref')) {
        window.location.hash = $(this).attr('data-ref')
      }
    })

    let selectedTab = window.location.hash
    selectedTab = selectedTab.toLowerCase()
    selectedTab = selectedTab.substr(1, selectedTab.length - 1)
    if (selectedTab) {
      $('html,body').animate({ scrollTop: $nav.offset().top }, 800)
      let navElement = $(`li[data-ref='${selectedTab}']`)
      if (navElement.length) {
        let tabIndex = $(navElement).attr('data-index')
        swiper.slideTo(tabIndex, 0, false)
      }
    }
  })
})

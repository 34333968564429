$(() => {
  $('.wpce-popup').each(function () {
    let $wrap = $(this)
    let uid = $wrap.data('uid')

    let activeClass = 'active'
    let $closeButton = $wrap.find('.close-button')
    let open = function () {
      $wrap.addClass(activeClass)
    }
    let close = function () {
      $wrap.removeClass(activeClass)
      history.replaceState(
        {},
        document.title,
        window.location.href.split('#')[0]
      )
    }
    $closeButton.click(close)
    if (window.location.hash === `#${uid}`) {
      open()
    }
    $(window).on('hashchange', function (e) {
      let hash = window.location.hash.substr(1, window.location.hash.length - 1)
      if (hash === uid) {
        open()
      } else if ($wrap.hasClass(activeClass)) {
        close()
      }
    })
  })
})

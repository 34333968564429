let $ = window.jQuery

class Accordion {
  static defaults = {
    toggleClass: '.accordion-header',
    toggleContainer: '.accordion-container',
    toggleButton: '.accordion-header',
    closeButton: '.accordion-close',
    singleOpen: true
  }

  static last = null

  constructor(element, options) {
    this.options = options
    this.$element = $(element)
    this.$toggleContainer = this.$element.find(options.toggleContainer)
    this.$toggleButton = this.$element.find(options.toggleButton)
    this.$closeButton = this.$element.find(options.closeButton)
    this.init()
  }

  init() {
    if (Accordion.last === null) Accordion.last = this

    // Set init attr
    this.$element.attr('data-accordion', '')

    // Hash handling
    this.handleHash()
    window.addEventListener('hashchange', this.handleHash.bind(this), false)

    // Toggle Button
    this.$toggleButton.click(() => {
      this.toggle()
    })
    // Close Button
    this.$closeButton.click(() => {
      this.close()
    })
  }

  isHashSelected() {
    return this.$element.attr('id') === window.location.hash.substr(1)
  }

  handleHash() {
    if (this.isHashSelected()) {
      this.open()
    } else {
      this.close()
    }
  }

  handleSingleOption() {
    if (this.options.singleOpen && Accordion.last !== this) {
      Accordion.last.close()
      Accordion.last = this
    }
  }

  toggle() {
    this.$element.toggleClass(this.options.toggleClass)
    if (this.$element.hasClass(this.options.toggleClass)) {
      this.open()
    } else {
      this.close()
    }
  }

  open() {
    this.handleSingleOption()
    this.$element.addClass(this.options.toggleClass)
    this.$toggleContainer.slideDown(300, () => {
      this.$toggleButton.trigger('accordion:toggled')
    })
  }

  close() {
    this.$element.removeClass(this.options.toggleClass)
    this.$toggleContainer.slideUp(300, () => {
      this.$toggleButton.trigger('accordion:toggled')
    })
  }
}

$.fn.Accordion = function (option) {
  return this.each(function () {
    let $this = $(this)
    let data = $this.data('Accordion')
    let options = $.extend(
      {},
      Accordion.defaults,
      $this.data(),
      typeof option == 'object' && option
    )
    if (!data) {
      $this.data('Accordion', (data = new Accordion(this, options)))
    }
  })
}

import Swiper from 'swiper'

$(() => {
  $('.wpce-swiper').each(function () {
    let $swiper = $(this)

    let swiper = new Swiper(`#${$swiper.data('swiper-id')}`, {
      // Optional parameters
      direction: 'horizontal',
      loop: false,
      slidesPerView: 3,
      spaceBetween: 30,
      centeredSlides: true,

      // If we need pagination
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      breakpointsInverse: true,
      breakpoints: {
        320: {
          slidesPerView: 1,
          initialSlide: 0
        },
        668: {
          slidesPerView: 2,
          initialSlide: 0
        },
        1200: {
          centeredSlides: true,
          slidesPerView: 3,
          initialSlide: 1
        }
      }
    })

    // Somehow doesn't work if initial value is 1,
    // so it's set after initialization.
    swiper.slideTo(1)

    $swiper.find('.custom-button-prev').click(() => {
      swiper.slidePrev()
    })
    $swiper.find('.custom-button-next').click(() => {
      swiper.slideNext()
    })
  })
})

// TODO: This should be refactored out of the main bundle.

let $ = window.jQuery

/*
 * GroupedImageGallery jQuery Plugin
 * (c) Copyright OWNBIT LLC
 * https://ownbit.net
 *
 * @date 21.02.2017
 * @author Michael Eichmann <michael.eichmann@ownbit.net>
 *
 * - Order images in collapsible groups
 * - display full size image in overlay (lightbox-like, with key shortcuts)
 * - customizable class names
 * - caption with alt fallback if no caption is defined (if option altCaptionFallback: true)
 * - works with other elements between image elements
 */

/*******************************
 * GroupedImageGallery
 ******************************/

/**
 * Simple image gallery with lightbox-like
 * full size image view and collapsible
 * image groups.
 *
 * @author    Michael Eichmann <michael.eichmann@ownbit.net>
 */
class GroupedImageGallery {
  static defaults = {
    /**
     * The element that is used as
     * the group container.
     */
    groupSelector: '.gallery-group',
    /**
     * Handle that is used to toggle
     * the group.
     */
    groupHandleSelector: '.toggle-handle',
    /**
     * The container that is used
     * as the overlay which contains
     * the full size images.
     */
    overlaySelector: '.gallery-overlay',
    /**
     *    Overlay content wrapper
     *  selector
     */
    overlayContentSelector: '.content',
    /**
     * If enabled: click on overlay
     * to close it.
     */
    overlayEasyExit: true,
    /**
     * Selector used to gather
     * close button elements
     * in the overlay.
     */
    overlayCloseButtonSelector: '.control-close',
    /**
     * The selector that is used to
     * add the click listener on the single images.
     */
    imageSelector: '.gallery-image',
    /**
     * Control for showing next image
     * in overlay.
     */
    nextSelector: '.control-next',
    /**
     * Control for showing previous
     * image in overlay.
     */
    prevSelector: '.control-prev',
    /**
     * Wheter you can press <- or ->
     * keys for showing next or prev image.
     */
    keyboardNavigationEnabled: true,
    /**
     * Selector used to fetch the
     * caption control (displays the caption).
     */
    captionControlSelector: '.control-caption',
    /**
     * Use alt attribute if no caption
     * is available
     */
    altCaptionFallback: true,
    /**
     * Open group from fragment (# anchor) in URL
     */
    openGroupFromUrl: true
  }

  static makeGroupId(groupName) {
    return groupName.toLowerCase().replace(/\ /g, '_')
  }

  /**
   * Gets group object from element.
   */
  static getGroupFrom($group) {
    return $group.data('ImageGalleryGroup')
  }

  constructor(element, options = {}) {
    this.options = options
    this.$element = $(element)
    this.$groups = this.$element.find(options.groupSelector)
    this.$images = this.$element.find(options.imageSelector)
    this.$current = null
    this.$overlay = this.$element.find(options.overlaySelector)
    this.$overlayCloseButton = this.$overlay.find(
      options.overlayCloseButtonSelector
    )
    this.$overlayContent = this.$overlay.find(options.overlayContentSelector)
    this.$next = this.$element.find(options.nextSelector)
    this.$prev = this.$element.find(options.prevSelector)
    this.$caption = this.$overlay.find(options.captionControlSelector)
    this.init()
  }

  /**
   * Initializes the Gallery:
   * Setting click listener,
   * initializes sub-plugins.
   */
  init() {
    let _this = this

    /*
     * Adding the groups click listener
     */
    this.$groups.ImageGalleryGroup({
      toggleHandleSelector: this.options.groupHandleSelector,
      toggleCallback: function (group) {
        _this.$groups.each(function () {
          let _group = $(this).data('ImageGalleryGroup')
          if (group != _group) {
            _group.close()
          }
        })
        group.toggle()
      }
    })

    /*
     * Image click listeners
     */
    this.$images.click(function (event) {
      event.stopPropagation()
      $image = $(this)
      _this.fillOverlayFrom($image)
      _this.toggleOverlay()
      _this.$current = $image
    })

    /*
     * Overlay easy exit (by clicking on overlay background)
     */
    if (this.options.overlayEasyExit) {
      var closeOverlayFunction = function (event) {
        event.stopPropagation()
        if (event.target == this) {
          _this.toggleOverlay()
        }
        return false
      }
      this.$overlay.click(closeOverlayFunction)
      this.$overlay
        .find(this.options.overlayContentSelector)
        .click(closeOverlayFunction)
    }

    /*
     * Overlay close button click listener
     */
    this.$overlayCloseButton.click(() => {
      this.closeOverlay()
    })

    /**
     * Next and prev button click listener
     */
    this.$next.click(() => {
      this.next()
    })
    this.$prev.click(() => {
      this.prev()
    })

    /**
     * Keyboard events in overlay
     * for next and prev as well as close
     */
    if (this.options.keyboardNavigationEnabled) {
      $(document).keydown(event => {
        if (this.isOverlayOpened()) {
          switch (event.key) {
            case 'Escape':
              this.closeOverlay()
              break
            case 'ArrowLeft':
              this.prev()
              break
            case 'ArrowRight':
              this.next()
              break
          }
        }
      })
    }

    /*
     * Open image group if anchor in url is given and
     * option is enabled
     */
    let groupId = GroupedImageGallery.makeGroupId(
      window.location.hash.substr(1)
    )
    if (this.options.openGroupFromUrl && groupId.length > 0) {
      this.openGroupById(groupId)
    }
  }

  /**
   * Opens a gallery group by Id.
   * @param groupId
   */
  openGroupById(groupId) {
    if (groupId) {
      this.closeAllGroups()
      let $group = this.$element.find('#' + groupId)
      if ($group.length > 0) {
        let group = GroupedImageGallery.getGroupFrom($group)
        // Also scroll to group
        $(document).scrollTop($group.offset().top)
        group.open()
      }
    }
  }

  /**
   * Shows the next image from the
   * current group in the overlay.
   */
  next() {
    if (this.$current != null && this.getNext().length > 0) {
      this.$current = this.getNext()
      this.fillOverlayFrom(this.$current)
    }
  }

  /**
   * Shows the previous image from the
   * current group in the overlay.
   */
  prev() {
    if (this.$current != null && this.getPrev().length > 0) {
      this.$current = this.getPrev()
      this.fillOverlayFrom(this.$current)
    }
  }

  /**
   * Returns the next image element
   * in the group using jQuery $.next().
   * @returns {*}
   */
  getNext() {
    return this.$current.next(this.options.imageSelector)
  }

  /**
   * Returns the previous image element
   * in the group using jQuery $.prev().
   * @returns {*}
   */
  getPrev() {
    return this.$current.prev(this.options.imageSelector)
  }

  /**
   * Toggles the overlay where the full size
   * images are being displayed.
   *
   * @param imageElement
   */
  toggleOverlay(imageElement) {
    this.$overlay.toggleClass('active')
  }

  /**
   * Closes the overlay.
   */
  closeOverlay() {
    this.$overlay.removeClass('active')
  }

  /**
   * Checks whether the overlay
   * is openend.
   */
  isOverlayOpened() {
    return this.$overlay.hasClass('active')
  }

  /**
   * Opens the overlay.
   */
  openOverlay() {
    this.$overlay.addClass('active')
  }

  /**
   * Fills the given image source, caption
   * and alt value in the overlay.
   * If caption is empty it uses the alt value (if enabled).
   *
   * TODO: Make it work, when content container is missing (it does not work right now)
   *
   * @param src        Image source to full resolution
   * @param caption    Image caption
   * @param alt        Image alt value
   */
  fillOverlay(src, caption, alt) {
    $content = this.$overlayContent
    if ($content) {
      $content.html('')
      $content.append("<img src='" + src + "' />")
    } else if (
      this.options.overlayContentSelector ==
      GroupedImageGallery.defaults.overlayContentSelector
    ) {
      this.$overlay.append(
        '<div class="' +
          this.options.overlayContentSelector.substr(0, 1) +
          '">' +
          '<img src=""' +
          src +
          '" /></div>'
      )
    } else {
      if (console) {
        console.error('No content wrapper in overlay found.')
      }
    }
    this.fillCaption(caption, alt)
  }

  /**
   * Fills overlay (image, caption) using a given
   * $image element (gathering data from attributes).
   *
   * @param $image    The image from which the attribute values should be taken.
   */
  fillOverlayFrom($image) {
    let src = $image.attr('data-full-src')
    if (src == null || src.length == 0) {
      src = $image.attr('src')
    }
    this.fillOverlay(src, $image.attr('data-caption'), $image.attr('alt'))
  }

  /**
   * Sets the desired caption in the overlay.
   * If caption is empty, it uses alt if not empty.
   * If not caption and no alt is given, removes
   * class "active" from caption control.
   * @param caption
   */
  fillCaption(caption, alt) {
    if (caption) {
      this.$caption.addClass('active')
      this.$caption.html(caption)
    } else if (this.options.altCaptionFallback && alt) {
      this.$caption.addClass('active')
      this.$caption.html(alt)
    } else {
      this.$caption.removeClass('active')
    }
  }

  /**
   * Toggles all groups.
   */
  toggleAllGroups() {
    this.$groups.each(function () {
      GroupedImageGallery.getGroupFrom($(this)).toggle()
    })
  }

  /**
   * Opens all groups.
   */
  openAllGroups() {
    this.$groups.each(function () {
      GroupedImageGallery.getGroupFrom($(this)).open()
    })
  }

  /**
   * Close all groups.
   */
  closeAllGroups() {
    this.$groups.each(function () {
      GroupedImageGallery.getGroupFrom($(this)).close()
    })
  }
}

/**
 * GroupedImageGallery jQuery plugin
 *
 * @param option
 * @returns {*}
 * @constructor
 */
$.fn.GroupedImageGallery = function (option) {
  return this.each(function () {
    let $this = $(this)
    let data = $this.data('GroupedImageGallery')
    let options = $.extend(
      {},
      GroupedImageGallery.defaults,
      $this.data(),
      typeof option == 'object' && option
    )
    if (!data) {
      $this.data(
        'GroupedImageGallery',
        (data = new GroupedImageGallery(this, options))
      )
    }
  })
}

/*******************************
 * ImageGalleryGroup
 ******************************/

/**
 * Represents an ImageGroup for
 * the GroupedImageGallery.
 */
class ImageGalleryGroup {
  static defaults = {
    /**
     * Handle that is used to toggle
     * the group.
     */
    toggleHandleSelector: '.toggle-handle',
    /**
     * The selector used to find the
     * group title.
     */
    titleSelector: '.group-title',
    /**
     * Callback that will be invoked
     * when toggling the group.
     */
    toggleCallback: () => {}
  }

  constructor(element, options) {
    this.options = options
    this.$element = $(element)
    this.$toggleHandle = this.$element.find(options.toggleHandleSelector)
    this.$groupTitle = this.$element.find(options.titleSelector)
    this.init()
  }

  init() {
    this.$element.click(() => {
      this.options.toggleCallback(this)
    })

    if (this.$groupTitle && this.$element.attr('id') == null) {
      this.$element.attr(
        'id',
        GroupedImageGallery.makeGroupId(this.$groupTitle.text())
      )
    }
  }

  /**
   * Toggles the group.
   */
  toggle() {
    this.$element.toggleClass('active')
  }

  /**
   * Closes the group.
   */
  close() {
    this.$element.removeClass('active')
  }

  /**
   * Opens the group.
   */
  open() {
    this.$element.addClass('active')
  }
}

$.fn.ImageGalleryGroup = function (option) {
  return this.each(function () {
    let $this = $(this)
    let data = $this.data('ImageGalleryGroup')
    let options = $.extend(
      {},
      ImageGalleryGroup.defaults,
      $this.data(),
      (typeof option == 'object' && option) || option
    )
    if (!data) {
      $this.data(
        'ImageGalleryGroup',
        (data = new ImageGalleryGroup(this, options))
      )
    }
  })
}

// TODO: This should be refactored out of the main bundle.

let $ = window.jQuery

class Menu {
  static defaults = {
    toggleClass: '.toggled',
    toggleContainer: '.toggle-container',
    toggleButton: '.toggle-button',
    closeButton: '.close-button'
  }

  constructor(element, options) {
    this.options = options
    this.$element = $(element)

    this.$toggleContainer = this.$element.find(options.toggleContainer)
    this.$toggleButton = this.$element.find(options.toggleButton)
    this.$closeButton = this.$element.find(options.closeButton)

    this.init()
  }

  init() {
    // Toggle Button
    this.$toggleButton.click(() => {
      this.$toggleContainer.toggleClass(this.options.toggleClass)
    })
    // Close Button
    this.$closeButton.click(() => {
      this.$toggleContainer.removeClass(this.options.toggleClass)
    })
  }
}

$.fn.Menu = function (option) {
  return this.each(function () {
    let $this = $(this)
    let data = $this.data('Menu')
    let options = $.extend(
      {},
      Menu.defaults,
      $this.data(),
      typeof option == 'object' && option
    )
    if (!data) {
      $this.data('Menu', (data = new Menu(this, options)))
    }
  })
}
